<template>
  <base-view>
    <template #top>
      <the-header no-top-link />
      <the-title-section :title="title" />
      <one-col-box>
        <h2>{{ $t('not_found.hl_error') }}</h2>
        <p>
          {{ $t('not_found.hl_countryblacklisted') }}
        </p>
      </one-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import OneColBox from '@/components/OneColBox.vue'

export default {
  components: {
    TheHeader,
    TheTitleSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBox
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit(
        'not-available-in-country-view-entered',
        to.path,
        from.path
      )
    })
  },
  computed: {
    title() {
      return this.$t('login.hl_welcome')
    }
  }
}
</script>
