var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-view", {
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("the-header", { attrs: { "no-top-link": "" } }),
            _c("the-title-section", { attrs: { title: _vm.title } }),
            _c("one-col-box", [
              _c("h2", [_vm._v(_vm._s(_vm.$t("not_found.hl_error")))]),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("not_found.hl_countryblacklisted")) +
                    "\n      "
                )
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "bottom",
        fn: function() {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }